import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CounterSection = () => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setPercentage(prevPercentage => {
        if (prevPercentage < 80) {
          return prevPercentage + 1;
        } else {
          clearInterval(timer);
          return 80;
        }
      });
    }, 50); // Adjust the speed as needed

    return () => clearInterval(timer);
  }, []);

  return (
    <section className="counter-four_bg">
      <div className="container pbmit-col-stretched-yes pbmit-col-right p-0">
        <div className="counter-one_bg">
          <div className="pbmit-col-stretched-right">
            <div className="pbminfotech-ele-fid-style-4">
              <div className="pbmit-fld-contents">
                <div className="pbmit-circle-outer">
                  <div className="pbmit-circle">
                    <div className="pbmit-fid-inner">
                      <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        styles={buildStyles({
                          textColor: "#ffffff",
                          pathColor: "#ffffff",
                          trailColor: "#ffffff33",
                          textSize: '24px'
                        })}
                      />
                    </div>
                    
                  </div>
                  <div className="pbmit-fid-sub">
                      <h3 className="pbmit-fid-title">
                        Newest Financing<br />
                        Options
                      </h3>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;
