import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';
import { CustomPrevArrow, CustomNextArrow } from './CustomArrow';

function OurProcess() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const processItems = [
    { year: "2012", title: "The Best of Financial Corp", desc: "Our partners with leading technology providers to guide our clients through the implementation process." },
    { year: "2015", title: "Silver Price Highest Portfolio", desc: "Our partners with leading technology providers to guide our clients through the implementation process." },
    { year: "2017", title: "Best Consult Company", desc: "Our partners with leading technology providers to guide our clients through the implementation process." },
    { year: "2020", title: "The Best of Financial Corp", desc: "Our partners with leading technology providers to guide our clients through the implementation process." },
    { year: "2022", title: "Research and Analysis", desc: "Our partners with leading technology providers to guide our clients through the implementation process." },
    { year: "2023", title: "Online Media Management", desc: "Our partners with leading technology providers to guide our clients through the implementation process." }
  ];

  return (
    <section className="section-lgx overflow-hidden">
      <div className="container">
        <div className="pbmit-element-miconheading-style-6">
          <div className="row align-items-center">
            <div className="col-md-10 col-xl-6">
              <div className="pbmit-heading-subheading text-white">
                <h4 className="pbmit-subtitle">Our process</h4>
                <h2 className="pbmit-title">Celebrating excellence in extraordinary achievements</h2>
              </div>
            </div>
            <div className="col-md-2 col-xl-6">
              <div className="process-slider_arrow swiper-btn-custom d-flex flex-row-reverse"></div>
            </div>
          </div>
          <div className="pbmit-elemnt-line">
            <Slider {...settings}>
              {processItems.map((item, index) => (
                <div key={index}>
                  <article className="pbmit-miconheading-style-6">
                    <div className="pbmit-ihbox-style-6">
                      <div className="pbmit-ihbox-headingicon">
                        <div className="pbmit-ihbox-icon">
                          <div className="pbmit-ihbox-icon-wrapper pbmit-ihbox-icon-type-text">{item.year}</div>
                        </div>
                        <div className="pbmit-ihbox-contents">
                          <h2 className="pbmit-element-title">
                            {item.title.split(' ').map((word, i) => (
                              <React.Fragment key={i}>
                                {word} {/* Add a space after each word */}
                              </React.Fragment>
                            ))}
                          </h2>
                          <div className="pbmit-heading-desc">{item.desc}</div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurProcess;
