import React from 'react';
import Marquee from 'react-simple-marquee';
 // Ensure to include your original CSS for styling

const NewsMarquee = () => {
    const newsItems = [
        " Stock Custodian *",
        " Market Research *",
        " Bitcoin Farming *",
        " Finance Advisor"
    ];

    return (
        <section className="section-lgb">
            <div className="container-fluid p-0">
                <Marquee speed={1} delay={1000}  >
                    {newsItems.map((item, index) => (
                        <div className="swiper-slide pbmit-marquee-effect-style-1" key={index}>
                            <div className="pbmit-tag-wrapper">
                                <h2 className="pbmit-element-title">
                                    {newsItems}
                                </h2>
                            </div>
                        </div>
                    ))}
                </Marquee>
            </div>
        </section>
    );
};

export default NewsMarquee;
