import React, { useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './style.css';
import slideImage from '../revolution/images/demo01-slide-a.jpg';
import slideImage1 from '../revolution/images/demo02-slide-a.jpg';
import slideImage2 from '../revolution/images/demo03-slide-a.jpg';


const BannerCarousel = () => {
    const carouselRef = useRef(null);

    const options = {
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        loop: true,
        margin: 0,
        dots: false,
        nav: true, // Disable default nav buttons
        items: 1,
        smartSpeed: 500,
        autoplay: true,
        autoplayTimeout: 9000,
        responsive: {
            0: { items: 1 },
            600: { items: 1 },
            1024: { items: 1 }
        }
    };


    return (
        <section className="main-slider style1">
            <div className="slider-box">
                <OwlCarousel
                    ref={carouselRef}
                    className="banner-carousel owl-theme owl-carousel"
                    {...options}
                >
                    <div className="slide">
                        <div className="image-layer" style={{ backgroundImage: `url(${slideImage})` }}></div>
                        <div className="shape-box"></div>
                        <div className="auto-container">
                            <div className="content">
                                <div className="big-title">
                                    <h2>
                                        Consulting<span className="dotted"></span><br />
                                        Financial<span className="dotted"></span><br />
                                        Advice<span className="dotted"></span>
                                    </h2>
                                </div>
                                <div className="btns-box">
                                    <a className="btn-one" href="about.html">
                                        <span className="txt">
                                            discover more<i className="icon-refresh arrow"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="image-layer" style={{ backgroundImage: `url(${slideImage1})` }}></div>
                        <div className="shape-box"></div>
                        <div className="auto-container">
                            <div className="content">
                                <div className="big-title">
                                    <h2>
                                    Expert <span className="dotted"></span><br />
                                        Consulting<span className="dotted"></span><br />
                                        Services<span className="dotted"></span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="image-layer" style={{ backgroundImage: `url(${slideImage2})` }}></div>
                        <div className="shape-box"></div>
                        <div className="auto-container">
                            <div className="content">
                                <div className="big-title">
                                    <h2>
                                        Consulting<span className="dotted"></span><br />
                                        Financial<span className="dotted"></span><br />
                                        Advice<span className="dotted"></span>
                                    </h2>
                                </div>
                                <div className="btns-box">
                                    <a className="btn-one" href="about.html">
                                        <span className="txt">
                                            discover more  <i className="icon-refresh arrow"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
                
            </div>
        </section>
    );
};

export default BannerCarousel;
