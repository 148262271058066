import React, { useState, useEffect } from 'react';
import './style.css'; // Make sure to include the appropriate CSS file
import img from '../images/logo.webp';
import { MdOutlineKeyboardArrowUp } from "react-icons/md";


const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Show button when page is scrolled up to given distance and update scroll position
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    if (position > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the scroll position to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Calculate the stroke-dashoffset for the progress circle
  const progress = Math.min(100, (scrollPosition / (document.documentElement.scrollHeight - window.innerHeight)) * 100);
  const dashOffset = 100 - progress;

  return (
    <footer className="footer site-footer pbmit-bg-color-blackish">
      <div className="pbmit-footer-big-area-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-3 col-md-6">
              <div className="pbmit-footer-logo">
                <img src={img} className="img-fluid" alt="Logo" />
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-6">
              <h3>Subscribe to our newsletter! Stay always in touch!</h3>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <form>
                <div className="pbmit-footer-newsletter">
                  <input type="email" name="EMAIL" placeholder="Enter your email" />
                  <button className="pbmit-svg-btn">
                    <svg
                      className="pbmit-svg-arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="10"
                      height="19"
                      viewBox="0 0 19 19"
                      xmlSpace="preserve"
                    >
                      <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                      <line x1="1.2" y1="1" x2="18" y2="1"></line>
                      <line x1="18" y1="17.8" x2="18" y2="1"></line>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="pbmit-footer-main-area">
        <div className="container">
          <div className="pbmit-footer-widget-area">
            <div className="container">
              <div className="row g-2">
                <div className="pbmit-footer-widget-col-1 col-md-4">
                  <div className="widget">
                    <h2 className="widget-title">Our address</h2>
                    <div className="pbmit-contact-widget-lines">
                      Valentin, Street Road 24, New <br /> York, USA - 67452
                    </div>
                  </div>
                </div>
                <div className="pbmit-footer-widget-col-2 col-md-4">
                  <div className="widget">
                    <h2 className="widget-title">Contact Us</h2>
                    <div className="pbmit-contact-widget-lines">
                      <div className="pbmit-contact-widget-phone">+(02) 574 - 328 - 301</div>
                      <div className="pbmit-contact-widget-email">noreply@pbminfotech.com</div>
                    </div>
                  </div>
                </div>
                <div className="pbmit-footer-widget-col-3 col-md-4">
                  <div className="widget">
                    <h2 className="widget-title">Our Social</h2>
                    <div className="textwidget">
                      <ul className="pbmit-social-links">
                        <li className="pbmit-social-li pbmit-social-facebook">
                          <a href="#" target="_blank" rel="noopener">
                            <span><i className="pbmit-base-icon-facebook-squared"></i></span>
                          </a>
                        </li>
                        <li className="pbmit-social-li pbmit-social-twitter">
                          <a href="#" target="_blank" rel="noopener">
                            <span><i className="pbmit-base-icon-twitter"></i></span>
                          </a>
                        </li>
                        <li className="pbmit-social-li pbmit-social-instagram">
                          <a href="#" target="_blank" rel="noopener">
                            <span><i className="pbmit-base-icon-instagram"></i></span>
                          </a>
                        </li>
                        <li className="pbmit-social-li pbmit-social-youtube">
                          <a href="#" target="_blank" rel="noopener">
                            <span><i className="pbmit-base-icon-youtube-play"></i></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pbmit-footer-text-area">
            <div className="container">
              <div className="pbmit-footer-text-inner">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pbmit-footer-copyright-text-area">
                      Copyright © 2023 <a href="#">Cleanfin</a>, All Rights Reserved.
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pbmit-footer-menu-area">
                      <ul>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVisible && (
        <div className="back-to-top" onClick={scrollToTop}>
          <svg width="40" height="40" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <path
              className="circle-bg"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              stroke="#eee"
              strokeWidth="1.5"
            />
            <path
              className="circle"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              stroke="#000"
              strokeWidth="1.5"
              strokeDasharray="100, 100"
              strokeDashoffset={dashOffset}
            />
            <MdOutlineKeyboardArrowUp className="arrow-icon" />
          </svg>
        </div>
      )}
    </footer>
  );
};

export default Footer;
