import React, {useState,useEffect} from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slideImage2 from '../images/homepage-4/mask-img.jpg';
import { FaChartLine, FaPhone, FaCogs, FaPlus } from 'react-icons/fa';
import Ourprocess from './Ourprocess';
import SectionMarquee from './SectionMarquee'
import CounterSection from './CounterSection';
import img from '../images/homepage-2/client/client-dark-01.png';
import img2 from '../images/homepage-2/client/client-dark-02.png';
import img3 from '../images/homepage-2/client/client-dark-03.png';
import img4 from '../images/rs=w_1300,h_800.webp';
import img5 from '../images/11-National-Susan-Day.jpg';
import img6 from '../images/shutterstock_587312612.jpg';
import img7 from '../images/Manager.jpg';
import img8 from '../images/1677254550-GettyImages-1453843862.jpg';
import img22 from '../images/homepage-2/client/client-dark-04.png';
import img23 from '../images/homepage-2/client/client-global-05.png';
import img9 from '../images/homepage-2/blog/blog-01.jpg';
import img10 from '../images/homepage-2/blog/blog-02.jpg';
import img11 from '../images/homepage-2/blog/blog-03.jpg';
import img12 from '../images/homepage-2/blog/blog-04.jpg';
import img13 from '../images/homepage-2/blog/blog-05.jpg';
import img14 from '../images/homepage-2/blog/blog-06.jpg';
import img21 from '../images/Black-Woman-computer.jpg';
import img20 from '../images/BlackMan-Website-2016-SEO.png';
import img19 from '../images/download.webp';
import img18 from '../images/DHS-hero-image-2000x1149.jpg';
import img24 from '../images/homepage-2/client/client-dark-05.png';
import img16 from '../images/shutterstock_587312612.jpg';
import img25 from '../images/homepage-2/client/client-global-06.png';
import img26 from '../images/homepage-2/client/client-dark-06.png';
import img30 from '../images/homepage-2/client/client-global-04.png';
import img31 from '../images/homepage-4/sticky-img-new.jpg';
import img15 from '../images/RvUFTEttcKemWqJaXkEZuD-1200-80.jpg';
import StickyImg from '../images/Black-men-1536x1024.jpeg';
import './style.css';
import StickyBox from "react-sticky-box";
import SplitType from 'split-type';
import splt from "https://cdn.skypack.dev/spltjs@1.0.8";
import anime from 'animejs/lib/anime.es.js';
import { FaPhoneAlt } from "react-icons/fa";
// import { gsap } from "gsap";
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
// gsap.registerPlugin(DrawSVGPlugin);

// Import and register GSAP (UMD/CommonJS)
//import { gsap } from "gsap/dist/gsap";
//import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";
//gsap.registerPlugin(DrawSVGPlugin);
function Innerpages() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,

    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }
  ]
  };
    
  const settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  const settings3 = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const blogPosts = [
  {
      title: " innovative strategies for outstanding. deblina",
      category: "Business",
      date: "14 Jul, 2023",
      image:img9,
      link: "blog-single-detail.html"
  },
  {
      title: "What Are The Other Roles Of Financial Advisor?",
      category: "Economy",
      date: "14 Jul, 2023",
      image:img10,
      link: "blog-single-detail.html"
  },
  {
      title: "How to Use the Investment Growth Calculator",
      category: "Finance",
      date: "14 Jul, 2023",
      image: img11,
      link: "blog-single-detail.html"
  },
  {
      title: "Consider When Buying Investment Property",
      category: "Insurance",
      date: "14 Jul, 2023",
      image: img12,
      link: "blog-single-detail.html"
  },
  {
      title: "What Are The Hidden Fees of Crypto/NFTs?",
      category: "Investment",
      date: "14 Jul, 2023",
      image: img13,
      link: "blog-single-detail.html"
  },
  {
      title: "Three Principles of Personal Finance: Success",
      category: "Research",
      date: "14 Jul, 2023",
      image:  img14,
      link: "blog-single-detail.html"
  }
];
const [hoveredDiv, setHoveredDiv] = useState(0);

const handleMouseEnter = (index) => {
  setHoveredDiv(index);
};

const handleMouseLeave = () => {
  setHoveredDiv(null);
};
const [percentage, setPercentage] = useState(0);
const [percentages, setPercentages] = useState(0);
useEffect(() => {
  const timer = setInterval(() => {
    setPercentage(prevPercentage => {
      if (prevPercentage < 80) {
        return prevPercentage + 1;
      } else {
        clearInterval(timer);
        return 80;
      }
    });
  }, 50); // Adjust the speed as needed

  return () => clearInterval(timer);
}, []);



useEffect(() => {
  const timer = setInterval(() => {
    setPercentages(prevPercentage => {
      if (prevPercentage < 95) {
        return prevPercentage + 1;
      } else {
        clearInterval(timer);
        return 95;
      }
    });
  }, 50); // Adjust the speed as needed

  return () => clearInterval(timer);
}, []);


//const text = new SplitType('#target2', { types: 'words, chars' })
// var split = new SplitType('#target2');
// const text = new SplitType({ types: "chars" });
// gsap.fromTo(
//   text.chars,
//   // {
//   //   color: bg
//   // },
//   {
//     // color: fg,
//     // duration: 1,
//     stagger: 1,
//     opacity: 1,
//     scrollTrigger: {
//       //trigger: char,
//       start: "top 80%",
//       end: "top 35%",
//       scrub: true,
//       markers: false,
//       toggleActions: "play play reverse reverse"
//     }
//   }
// );
useEffect(() => {
  const splt = async () => {
    const { default: spltjs } = await import('https://cdn.skypack.dev/spltjs@1.0.8');
    spltjs({});
  };

  splt();

  anime({
    targets: '.char',
    translateY: [0, 40],
    direction: 'alternate',
    loop: true,
    delay: anime.stagger(50),
  });
}, []);
      

  return (
   <>
      <div className="page-content pbmit-bg-color-global">
        

       <section className="section-lgx who-we-are-sec_four">
      <div className="container">
        <div className="row g-0">
          <div className="col-md-5 pbmit-col_left">
            <div className="pbmit-subheading">
              <h4 className="pbmit-subtitle">Who We Are</h4>
            </div>
            <div className="pbminfotech-ele-fid-style-6">
              <div className="pbmit-fld-contents">
                <div className="pbmit-fld-wrap">
                  <h4 className="pbmit-fid-inner">
                    <span className="pbmit-number-rotate numinate" data-appear-animation="animateDigits" data-from="0" data-to="15" data-interval="5" data-before="" data-before-style="" data-after="" data-after-style="">15</span>
                    <span className="pbmit-fid">
                      <span>+</span>
                    </span>
                  </h4>
                  <h3 className="pbmit-fid-title">
                    Years of <br /> Experience
                  </h3>
                </div>
              </div>
            </div>
            <div className="pbminfotech-ele-fid-style-6">
              <div className="pbmit-fld-contents">
                <div className="pbmit-fld-wrap">
                  <h4 className="pbmit-fid-inner">
                    <span className="pbmit-number-rotate numinate" data-appear-animation="animateDigits" data-from="0" data-to="218" data-interval="5" data-before="" data-before-style="" data-after="" data-after-style="">218</span>
                  </h4>
                  <h3 className="pbmit-fid-title">
                    Active users <br /> around the world
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 pbmit-col_right">
            <div className="pbmit-heading-subheading text-white">
              <h2 className="pbmit-title">
                <div className='' style={{ position: 'relative', display: 'inline-block', opacity: 1, transform: 'translate(0px, 0px)' }}>
                Integrity, trust, and client centricity guide our enterprise website, driving everything we do.
                </div>
              </h2>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="who-we-are-four_right_img"></div>
              </div>
              <div className="col-md-6">
                <div className="pbmit_desc">
                  <p>At FinFlow, we understand the importance of financial planning for individuals and businesses alike.</p>
                  <p>Our team of expert financial planners is committed to helping you achieve your financial goals and secure your financial future.</p>
                </div>
              </div>
            </div>
            <div className="ihbox-style-7_box">
              <div className="row">
                <div className="col-md-6 col-xl-4">
                  <div className="pbmit-ihbox-style-7">
                    <div className="pbmit-ihbox-contents">
                      <h2 className="pbmit-element-title">
                        <a href="service-detail.html">
                          <span>Business Strategy <br /> Development</span>
                        </a>
                      </h2>
                      <div className="pbmit-ihbox-btn">
                        <a href="service-detail.html">
                          <span>
                            <svg className="pbmit-svg-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="19" height="19" viewBox="0 0 19 19" xmlSpace="preserve">
                              <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                              <line x1="1.2" y1="1" x2="18" y2="1"></line>
                              <line x1="18" y1="17.8" x2="18" y2="1"></line>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4">
                  <div className="pbmit-ihbox-style-7">
                    <div className="pbmit-ihbox-contents">
                      <h2 className="pbmit-element-title">
                        <a href="service-detail.html">
                          <span>Market Research<br />and Analysis</span>
                        </a>
                      </h2>
                      <div className="pbmit-ihbox-btn">
                        <a href="service-detail.html">
                          <span>
                            <svg className="pbmit-svg-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="19" height="19" viewBox="0 0 19 19" xmlSpace="preserve">
                              <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                              <line x1="1.2" y1="1" x2="18" y2="1"></line>
                              <line x1="18" y1="17.8" x2="18" y2="1"></line>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4">
                  <div className="pbmit-ihbox-style-7">
                    <div className="pbmit-ihbox-contents">
                      <h2 className="pbmit-element-title">
                        <a href="service-detail.html">
                          <span>Technology <br /> Solutions</span>
                        </a>
                      </h2>
                      <div className="pbmit-ihbox-btn">
                        <a href="service-detail.html">
                          <span>
                            <svg className="pbmit-svg-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="19" height="19" viewBox="0 0 19 19" xmlSpace="preserve">
                              <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                              <line x1="1.2" y1="1" x2="18" y2="1"></line>
                              <line x1="18" y1="17.8" x2="18" y2="1"></line>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="pbmit-element-service-style-4">
      <div className="container-fluid">
        <div className="pbmit-element-posts-wrapper row">
          <article className={`pbmit-service-style-4  ${hoveredDiv === 0 ? 'pbmit-active' : ''}`}
        onMouseEnter={() => handleMouseEnter(0)}
        onMouseLeave={handleMouseLeave}>
            <div className="pbminfotech-post-item">
              <div className="pbmit-service-img-wrapper" style={{ backgroundImage: `url(${img4})` }}>
                <div className="pbmit-featured-img-wrapper">
                  <div className="pbmit-featured-wrapper">
                    <img src= {slideImage2} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
              <div className="pbminfotech-box-content">
                <div className="pbmit-content-inner">
                  <div className="pbminfotech-box-number">01</div>
                  <div className="pbmit-cat-wrap">
                    <div className="pbmit-serv-cat">
                      <a href="service-detail.html" rel="tag">Advisory</a>
                    </div>
                    <h3 className="pbmit-service-title">
                      <a href="service-detail.html">Consulting Service</a>
                    </h3>
                  </div>
                </div>
                <div className="pbmit-svg-btn">
                  <a className="btn-arrow" href="service-detail.html">
                    <svg className="pbmit-svg-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="10" height="19" viewBox="0 0 19 19" xmlSpace="preserve">
                      <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                      <line x1="1.2" y1="1" x2="18" y2="1"></line>
                      <line x1="18" y1="17.8" x2="18" y2="1"></line>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="pbminfotech-icon-box">
                <i className="pbmit-cleanfin-icon pbmit-cleanfin-icon-global"></i>  
              </div>
            </div>
            <a href="service-detail.html" className="pbmit-link"></a>
          </article>
          <article className={`pbmit-service-style-4 ${hoveredDiv === 1 ? 'pbmit-active' : ''}`}
        onMouseEnter={() => handleMouseEnter(1)}
        onMouseLeave={handleMouseLeave}>
            <div className="pbminfotech-post-item">
              <div className="pbmit-service-img-wrapper" style={{ backgroundImage: `url(${img5})` }}>
                <div className="pbmit-featured-img-wrapper">
                  <div className="pbmit-featured-wrapper">
                    <img src= {img5} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
              <div className="pbminfotech-box-content">
                <div className="pbmit-content-inner">
                  <div className="pbminfotech-box-number">02</div>
                  <div className="pbmit-cat-wrap">
                    <div className="pbmit-serv-cat">
                      <a href="service-detail.html" rel="tag">Consulting</a>
                    </div>
                    <h3 className="pbmit-service-title">
                      <a href="service-detail.html">Inventory Management</a>
                    </h3>
                  </div>
                </div>
                <div className="pbmit-svg-btn">
                  <a className="btn-arrow" href="service-detail.html">
                    <svg className="pbmit-svg-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="10" height="19" viewBox="0 0 19 19" xmlSpace="preserve">
                      <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                      <line x1="1.2" y1="1" x2="18" y2="1"></line>
                      <line x1="18" y1="17.8" x2="18" y2="1"></line>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="pbminfotech-icon-box">
                <i className="pbmit-cleanfin-icon pbmit-cleanfin-icon-global"></i>  
              </div>
            </div>
            <a href="service-detail.html" className="pbmit-link"></a>
          </article>

          <article className={`pbmit-service-style-4 ${hoveredDiv === 2 ? 'pbmit-active' : ''}`}
        onMouseEnter={() => handleMouseEnter(2)}
        onMouseLeave={handleMouseLeave}>
            <div className="pbminfotech-post-item">
              <div className="pbmit-service-img-wrapper" style={{ backgroundImage: `url(${img6})` }}>
                <div className="pbmit-featured-img-wrapper">
                  <div className="pbmit-featured-wrapper">
                    <img src={img5} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
              <div className="pbminfotech-box-content">
                <div className="pbmit-content-inner">
                  <div className="pbminfotech-box-number">03</div>
                  <div className="pbmit-cat-wrap">
                    <div className="pbmit-serv-cat">
                      <a href="service-detail.html" rel="tag">Inventory</a>
                    </div>
                    <h3 className="pbmit-service-title">
                      <a href="service-detail.html">Business Planing</a>
                    </h3>
                  </div>
                </div>
                <div className="pbmit-svg-btn">
                  <a className="btn-arrow" href="service-detail.html">
                    <svg className="pbmit-svg-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="10" height="19" viewBox="0 0 19 19" xmlSpace="preserve">
                      <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                      <line x1="1.2" y1="1" x2="18" y2="1"></line>
                      <line x1="18" y1="17.8" x2="18" y2="1"></line>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="pbminfotech-icon-box">
                <i className="pbmit-cleanfin-icon pbmit-cleanfin-icon-global"></i>  
              </div>
            </div>
            <a href="service-detail.html" className="pbmit-link"></a>
          </article>

          <article className={`pbmit-service-style-4 ${hoveredDiv === 3 ? 'pbmit-active' : ''}`}
        onMouseEnter={() => handleMouseEnter(3)}
        onMouseLeave={handleMouseLeave}>
            <div className="pbminfotech-post-item">
              <div className="pbmit-service-img-wrapper" style={{ backgroundImage: `url(${img7})` }}>
                <div className="pbmit-featured-img-wrapper">
                  <div className="pbmit-featured-wrapper">
                    <img src= {img5} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
              <div className="pbminfotech-box-content">
                <div className="pbmit-content-inner">
                  <div className="pbminfotech-box-number">04</div>
                  <div className="pbmit-cat-wrap">
                    <div className="pbmit-serv-cat">
                      <a href="service-detail.html" rel="tag">Management</a>
                    </div>
                    <h3 className="pbmit-service-title">
                      <a href="service-detail.html">Wealth Management</a>
                    </h3>
                  </div>
                </div>
                <div className="pbmit-svg-btn">
                  <a className="btn-arrow" href="service-detail.html">
                    <svg className="pbmit-svg-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="10" height="19" viewBox="0 0 19 19" xmlSpace="preserve">
                      <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                      <line x1="1.2" y1="1" x2="18" y2="1"></line>
                      <line x1="18" y1="17.8" x2="18" y2="1"></line>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="pbminfotech-icon-box">
                <i className="pbmit-cleanfin-icon pbmit-cleanfin-icon-global"></i>  
              </div>
            </div>
            <a href="service-detail.html" className="pbmit-link"></a>
          </article>

          <article className={`pbmit-service-style-4 ${hoveredDiv === 4 ? 'pbmit-active' : ''}`}
        onMouseEnter={() => handleMouseEnter(4)}
        onMouseLeave={handleMouseLeave}>
            <div className="pbminfotech-post-item">
              <div className="pbmit-service-img-wrapper" style={{ backgroundImage: `url(${img8})` }}>
                <div className="pbmit-featured-img-wrapper">
                  <div className="pbmit-featured-wrapper">
                    <img src={img5} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
              <div className="pbminfotech-box-content">
                <div className="pbmit-content-inner">
                  <div className="pbminfotech-box-number">05</div>
                  <div className="pbmit-cat-wrap">
                    <div className="pbmit-serv-cat">
                      <a href="service-detail.html" rel="tag">Marketing</a>
                    </div>
                    <h3 className="pbmit-service-title">
                      <a href="service-detail.html">Inventory Management</a>
                    </h3>
                  </div>
                </div>
                <div className="pbmit-svg-btn">
                  <a className="btn-arrow" href="service-detail.html">
                    <svg className="pbmit-svg-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="10" height="19" viewBox="0 0 19 19" xmlSpace="preserve">
                      <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                      <line x1="1.2" y1="1" x2="18" y2="1"></line>
                      <line x1="18" y1="17.8" x2="18" y2="1"></line>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="pbminfotech-icon-box">
                <i className="pbmit-cleanfin-icon pbmit-cleanfin-icon-global"></i>  
              </div>
            </div>
            <a href="service-detail.html" className="pbmit-link"></a>
          </article>
          {/* Repeat the above structure for other articles */}
        </div>
      </div>
    </section>
  <section className="section-lgx ihbox-section_four">
      <div className="container">
        <div className="row g-0">
          <div className="col-md-7 pbmit-col_1">
            <div className="pbmit-mask-image pbmit-mask-image-style-1">
              <div className="pbmit-mask-image-box">
                <div
                  className="pbmit-mask-image-title-image"
                  style={{ backgroundImage: `url(${slideImage2})` }}
                >
                  <h2 className="pbmit-element-title">How we work</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 pbmit-col_2"></div>
        </div>
        <div className="ihbox_style">
          <div className="row g-0">
            <div className="col-md-6 col-xl-4 pbmit-col-wrapper">
              <div className="pbmit-ihbox-style-5">
                <div className="pbmit-ihbox-contents">
                  <div className="pbmit-ihbox-icon">
                    <div className="pbmit-ihbox-icon-wrapper">
                      <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaChartLine className="pbmit-cleanfin-icon pbmit-cleanfin-icon-investment" /></i>
                      </div>
                    </div>
                  </div>
                  <h2 className="pbmit-element-title">Investment Planning</h2>
                  <div className="pbmit-heading-desc">Our mission is to empower you with the knowledge and tools to make informed decisions.</div>
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Medical Devices & Technology</span>
                    </li>
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Retail & E-commerce</span>
                    </li>
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Energy and Utilities</span>
                    </li>
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Hospitality and Tourism</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 pbmit-col-wrapper">
              <div className="pbmit-ihbox-style-5">
                <div className="pbmit-ihbox-contents">
                  <div className="pbmit-ihbox-icon">
                    <div className="pbmit-ihbox-icon-wrapper">
                      <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPhoneAlt  className="pbmit-cleanfin-icon pbmit-cleanfin-icon-call-center" /></i>
                      </div>
                    </div>
                  </div>
                  <h2 className="pbmit-element-title">Retirement Planning</h2>
                  <div className="pbmit-heading-desc">Our mission is to empower you with the knowledge and tools to make informed decisions.</div>
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Financial Services</span>
                    </li>
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Financial Institutions</span>
                    </li>
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Healthcare & Pharmaceuticals</span>
                    </li>
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Education</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4 pbmit-col-wrapper">
              <div className="pbmit-ihbox-style-5">
                <div className="pbmit-ihbox-contents">
                  <div className="pbmit-ihbox-icon">
                    <div className="pbmit-ihbox-icon-wrapper">
                      <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaCogs className="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1" /></i>
                      </div>
                    </div>
                  </div>
                  <h2 className="pbmit-element-title">Education Planning</h2>
                  <div className="pbmit-heading-desc">Our mission is to empower you with the knowledge and tools to make informed decisions.</div>
                  <ul className="list-group list-group-borderless">
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Medical Devices & Technology</span>
                    </li>
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>

                      </span>
                      <span className="pbmit-icon-list-text">Retail & E-commerce</span>
                    </li>
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Energy and Utilities</span>
                    </li>
                    <li className="list-group-item">
                      <span className="pbmit-icon-list-icon">
                      <i class="pbmit-cleanfin-icon pbmit-cleanfin-icon-setting-1">
                        <FaPlus aria-hidden="true" className="pbmit-base-icon-plus" /></i>
                      </span>
                      <span className="pbmit-icon-list-text">Hospitality and Tourism</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </section>
        <section className="pbmit-sticky-special">
          <div className="container-fluid p-0">
            <div className="row1 g-0 who_we_aree">
              <StickyBox offsetTop={120} offsetBottom={0} className="col-md-6 pbmit-col_left" >
                 <div className="about-us-four_leftbox1">
              <img src={StickyImg} style={{ maxWidth: "100%" }} alt="Sticky" />
            </div>
              </StickyBox>
               <div className="col-md-6 pbmit-col_right pbmit-sticky-col2-special">
            <div className="about-us-four_rightbox">
              <div className="pbmit-heading-subheading text-white">
                <h4 className="pbmit-subtitle">WHO we are</h4>
                <h2 className="pbmit-title">Building bridges for sustainable and growth</h2>
                <div className="pbmit-heading-desc">
                  At FinFlow, we understand the importance of financial planning for individuals and businesses alike. Our team of expert financial planners is committed to helping you achieve your financial goals and secure your financial future.
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="pbminfotech-ele-fid-style-5">
                    <div className="pbmit-fld-contents">
                      <div className="pc" data-digit="80" data-fill="#ffffff" data-emptyfill="#ffffff33" data-before="" data-after="<sup>%</sup>" data-thickness="2" data-size="130">
                        <div className="pbmit-circle">
                          <div className="pbmit-fid-inner">
                            <span className="pbmit-number-rotate numinate">
                             
                            </span>
                          </div>
                        </div>
                        <div className="pbmit-fid-sub">
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pbminfotech-ele-fid-style-5">
                    <div className="pbmit-fld-contents">
                      <div className="pbmit-circle-outer" data-digit="95" data-fill="#ffffff" data-emptyfill="#ffffff33" data-before="" data-after="<sup>%</sup>" data-thickness="2" data-size="130">
                        <div className="pbmit-circle">
                          <div className="pbmit-fid-inner">
                            <span className="pbmit-number-rotate numinate">
                           
                            </span>
                          </div>
                        </div>
                        <div className="pbmit-fid-sub">
                       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h5 className="pbmit-heading">Analyze Your Business.</h5>
              <div className="pbmit-heading_desc">We understand the importance of financial planning for individuals and businesses alike. Our team of expert financial planners is committed to helping you achieve your financial goals and secure your financial future.</div>
              <div className="sticky-img_box">
                <img src= {img31}className="img-fluid" alt="" />
              </div>
              <ul className="list-group list-group-borderless">
                <li className="list-group-item">
                  <span className="pbmit-icon-list-icon">
                    <i aria-hidden="true" className="ti-check"></i>
                  </span>
                  <span className="pbmit-icon-list-text">Translating the design into code using Webflow</span>
                </li>
                <li className="list-group-item">
                  <span className="pbmit-icon-list-icon">
                    <i aria-hidden="true" className="ti-check"></i>
                  </span>
                  <span className="pbmit-icon-list-text">Responsive and functions perfectly across all devices</span>
                </li>
                <li className="list-group-item">
                  <span className="pbmit-icon-list-icon">
                    <i aria-hidden="true" className="ti-check"></i>
                  </span>
                  <span className="pbmit-icon-list-text">Testing the website thoroughly before launch</span>
                </li>
              </ul>
              <div className="pbmit-heading_desc">We understand the importance of financial planning for individuals and businesses alike. Our team of expert financial planners is committed to helping you achieve your financial goals and secure your financial future.</div>
              <div className="pbmit-ihbox-style-2">
                <div className="pbmit-ihbox-icon">
                  <div className="pbmit-ihbox-icon-wrapper">
                    <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                      <i className="pbmit-cleanfin-icon pbmit-cleanfin-icon-investment"></i>
                    </div>
                  </div>
                </div>
                <div className="pbmit-ihbox-contents">
                  <h2 className="pbmit-element-title">
                    Market Research and Analysis
                  </h2>
                  <div className="pbmit-heading-desc">Our Market Research and Analysis services are designed to provide businesses with deep insights and a clear understanding of their market landscape. We conduct thorough research to gather valuable data on market trends, customer behavior, and competitive dynamics. Our expert analysts interpret this data to deliver actionable recommendations that inform strategic decisions and help you stay ahead of the competition. Whether you're looking to enter a new market, launch a new product, or optimize your existing strategies, our market research and analysis services offer the clarity and direction you need to succeed.</div>
                </div>
              </div>
            </div>
          </div>

            </div>
            </div>

         </section>
    <Ourprocess/>
    <SectionMarquee/>
    <CounterSection/>
          <section className="testimonial_four">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-3">
            <div className="pbmit-heading-subheading animation-style2">
              <h4 className="pbmit-subtitle">Testimonials</h4>
              <h2 className="pbmit-title">What our clients say</h2>
            </div>
          </div>
          <div className="col-md-12 col-lg-9">
            <div className="testimonial-four_box">
              <Slider {...settings2}>
                <div>
                  <article className="pbmit-testimonial-style-2">
                    <div className="pbminfotech-post-item">
                      <div className="pbminfotech-box-content">
                        <blockquote className="pbminfotech-testimonial-text">
                          <p>
                          I sincerely thank the team for their exceptional and effective service to the company's operations. After seven years of collaboration, I highly recommend their services.
                          </p>
                        </blockquote>
                        <div className="pbminfotech-box-star-ratings">
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                        </div>
                        <div className="pbminfotech-box-content-inner">
                          <div className="pbmit-featured-img-wrapper">
                            <div className="pbmit-featured-wrapper">
                              <img src={img15} className="img-fluid" alt="" />
                            </div>
                          </div>
                          <div className="pbminfotech-box-author">
                            <h3 className="pbminfotech-box-title">Stephen Welch</h3>
                            <div className="pbminfotech-testimonial-detail">Satisfied Client</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div>
                  <article className="pbmit-testimonial-style-2">
                    <div className="pbminfotech-post-item">
                      <div className="pbminfotech-box-content">
                        <blockquote className="pbminfotech-testimonial-text">
                          <p>
                          I extend my heartfelt gratitude to the team for their outstanding and efficient service to the company's operations. After seven years of collaboration, I highly recommend their services.
                          </p>
                        </blockquote>
                        <div className="pbminfotech-box-star-ratings">
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star"></i>
                        </div>
                        <div className="pbminfotech-box-content-inner">
                          <div className="pbmit-featured-img-wrapper">
                            <div className="pbmit-featured-wrapper">
                              <img src={img16} className="img-fluid" alt="" />
                            </div>
                          </div>
                          <div className="pbminfotech-box-author">
                            <h3 className="pbminfotech-box-title">Martin Bailey</h3>
                            <div className="pbminfotech-testimonial-detail">Chief Finance</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div>
                  <article className="pbmit-testimonial-style-2">
                    <div className="pbminfotech-post-item">
                      <div className="pbminfotech-box-content">
                        <blockquote className="pbminfotech-testimonial-text">
                          <p>
                          I express my sincere gratitude to the team for their exceptional and effective service to the company's activities. After seven years of collaboration, I highly recommend their services.
                          </p>
                        </blockquote>
                        <div className="pbminfotech-box-star-ratings">
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star"></i>
                          <i className="pbmit-base-icon-star"></i>
                        </div>
                        <div className="pbminfotech-box-content-inner">
                          <div className="pbmit-featured-img-wrapper">
                            <div className="pbmit-featured-wrapper">
                              <img src= {img21} className="img-fluid" alt="" />
                            </div>
                          </div>
                          <div className="pbminfotech-box-author">
                            <h3 className="pbminfotech-box-title">Emma Greed</h3>
                            <div className="pbminfotech-testimonial-detail">Legal Advisor</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div>
                  <article className="pbmit-testimonial-style-2">
                    <div className="pbminfotech-post-item">
                      <div className="pbminfotech-box-content">
                        <blockquote className="pbminfotech-testimonial-text">
                          <p>
                          I want to express my profound gratitude to the team for their impeccable and productive service to the company's activities over our seven years of joint work. I wholeheartedly recommend their services.
                          </p>
                        </blockquote>
                        <div className="pbminfotech-box-star-ratings">
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                        </div>
                        <div className="pbminfotech-box-content-inner">
                          <div className="pbmit-featured-img-wrapper">
                            <div className="pbmit-featured-wrapper">
                              <img src= {img20} className="img-fluid" alt="" />
                            </div>
                          </div>
                          <div className="pbminfotech-box-author">
                            <h3 className="pbminfotech-box-title">Daniel Craig</h3>
                            <div className="pbminfotech-testimonial-detail">Tax Manager</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div>
                  <article className="pbmit-testimonial-style-2">
                    <div className="pbminfotech-post-item">
                      <div className="pbminfotech-box-content">
                        <blockquote className="pbminfotech-testimonial-text">
                          <p>
                          I express my heartfelt appreciation to the team for their impeccable and productive service to the company's activities over the course of our seven years of collaboration. I confidently recommend their services.
                          </p>
                        </blockquote>
                        <div className="pbminfotech-box-star-ratings">
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star"></i>
                        </div>
                        <div className="pbminfotech-box-content-inner">
                          <div className="pbmit-featured-img-wrapper">
                            <div className="pbmit-featured-wrapper">
                              <img src={img19} className="img-fluid" alt="" />
                            </div>
                          </div>
                          <div className="pbminfotech-box-author">
                            <h3 className="pbminfotech-box-title">Amelia Dyer</h3>
                            <div className="pbminfotech-testimonial-detail">Stockbroker</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div>
                  <article className="pbmit-testimonial-style-2">
                    <div className="pbminfotech-post-item">
                      <div className="pbminfotech-box-content">
                        <blockquote className="pbminfotech-testimonial-text">
                          <p>
                          I extend my profound appreciation to the team for their impeccable and productive service to the company activities, reflecting our seven years of collaborative work. I confidently recommend their services.
                          </p>
                        </blockquote>
                        <div className="pbminfotech-box-star-ratings">
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star pbmit-active"></i>
                          <i className="pbmit-base-icon-star"></i>
                          <i className="pbmit-base-icon-star"></i>
                        </div>
                        <div className="pbminfotech-box-content-inner">
                          <div className="pbmit-featured-img-wrapper">
                            <div className="pbmit-featured-wrapper">
                              <img src={img18} className="img-fluid" alt="" />
                            </div>
                          </div>
                          <div className="pbminfotech-box-author">
                            <h3 className="pbminfotech-box-title">Henry Cavil</h3>
                            <div className="pbminfotech-testimonial-detail">Cost Marketing</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
 <section className="client_four pbmit-element-client-style-1">
      <div className="container-fluid pbmit-col-stretched-yes pbmit-col-right">
        <div className="row">
          <div className="col-md-12">
            <div className="pbmit-col-stretched-right">
              <div className="swiper-slider">
                <Slider {...settings}>
                  <div className="pbmit-client-style-1 swiper-slide">
                    <div className="pbmit-client-content">
                      <div className="pbmit-client-wrapper pbmit-client-with-hover-img">
                        <h4 className="pbmit-hide">Client 05</h4>
                        <div className="pbmit-client-hover-img">
                          <img src={img3} className="img-fluid" alt="" />
                        </div>
                        <div className="pbmit-featured-img-wrapper">
                          <div className="pbmit-featured-wrapper">
                            <img src={img} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-client-style-1 swiper-slide">
                    <div className="pbmit-client-content">
                      <div className="pbmit-client-wrapper pbmit-client-with-hover-img">
                        <h4 className="pbmit-hide">Client 05</h4>
                        <div className="pbmit-client-hover-img">
                          <img src={img2}  className="img-fluid" alt="" />
                        </div>
                        <div className="pbmit-featured-img-wrapper">
                          <div className="pbmit-featured-wrapper">
                            <img src= {img2}className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-client-style-1 swiper-slide">
                    <div className="pbmit-client-content">
                      <div className="pbmit-client-wrapper pbmit-client-with-hover-img">
                        <h4 className="pbmit-hide">Client 05</h4>
                        <div className="pbmit-client-hover-img">
                          <img src= {img3} className="img-fluid" alt="" />
                        </div>
                        <div className="pbmit-featured-img-wrapper">
                          <div className="pbmit-featured-wrapper">
                            <img src={img3} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-client-style-1 swiper-slide">
                    <div className="pbmit-client-content">
                      <div className="pbmit-client-wrapper pbmit-client-with-hover-img">
                        <h4 className="pbmit-hide">Client 05</h4>
                        <div className="pbmit-client-hover-img">
                          <img src= {img30} className="img-fluid" alt="" />
                        </div>
                        <div className="pbmit-featured-img-wrapper">
                          <div className="pbmit-featured-wrapper">
                            <img src= {img22} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-client-style-1 swiper-slide">
                    <div className="pbmit-client-content">
                      <div className="pbmit-client-wrapper pbmit-client-with-hover-img">
                        <h4 className="pbmit-hide">Client 05</h4>
                        <div className="pbmit-client-hover-img">
                          <img src= {img23} className="img-fluid" alt="" />
                        </div>
                        <div className="pbmit-featured-img-wrapper">
                          <div className="pbmit-featured-wrapper">
                            <img src= {img24}className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-client-style-1 swiper-slide">
                    <div className="pbmit-client-content">
                      <div className="pbmit-client-wrapper pbmit-client-with-hover-img">
                        <h4 className="pbmit-hide">Client 05</h4>
                        <div className="pbmit-client-hover-img">
                          <img src= {img25} className="img-fluid" alt="" />
                        </div>
                        <div className="pbmit-featured-img-wrapper">
                          <div className="pbmit-featured-wrapper">
                            <img src= {img26} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pbmit-client-style-1 swiper-slide">
                    <div className="pbmit-client-content">
                      <div className="pbmit-client-wrapper pbmit-client-with-hover-img">
                        <h4 className="pbmit-hide">Client 05</h4>
                        <div className="pbmit-client-hover-img">
                          <img src={img25} className="img-fluid" alt="" />
                        </div>
                        <div className="pbmit-featured-img-wrapper">
                          <div className="pbmit-featured-wrapper">
                            <img src= {img26} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
 <section className="section-lgx blog_four">
            <div className="container">
                <div className="pbmit-heading-subheading text-white text-center animation-style2">
                    <h4 className="pbmit-subtitle">Latest Blog</h4>
                    <h2 className="pbmit-title">Latest news coming</h2>
                </div>
                <Slider {...settings3}>
                    {blogPosts.map((post, index) => (
                        <div className="slick-slide" key={index}>
                            <article className="pbmit-ele-blog pbmit-blog-style-1">
                                <div className="post-item">
                                    <div className="pbmit-featured-container">
                                        <div className="pbmit-featured-img-wrapper">
                                            <div className="pbmit-featured-wrapper">
                                                <img src={post.image} className="img-fluid" alt={post.title} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pbminfotech-box-content">
                                        <div className="pbmit-meta-cat-wrapper pbmit-meta-line d-flex align-items-center">
                                            <div className="pbmit-meta-category">
                                                <a href={post.link} rel="category tag">{post.category}</a>
                                            </div>
                                            <div className="pbmit-meta-date">
                                                <span className="pbmit-date-wrap">{post.date}</span>
                                            </div>
                                        </div>
                                        <h3 className="pbmit-post-title">
                                            <a href={post.link}>{post.title}</a>
                                        </h3>
                                        <div className="pbmit-svg-btn">
                                            <a className="btn-arrow" href={post.link}>
                                                Read More
                                                <svg className="pbmit-svg-arrow" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="10" height="19" viewBox="0 0 19 19" xmlSpace="preserve">
                                                    <line x1="1" y1="18" x2="17.8" y2="1.2"></line>
                                                    <line x1="1.2" y1="1" x2="18" y2="1"></line>
                                                    <line x1="18" y1="17.8" x2="18" y2="1"></line>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <a href={post.link} className="pbmit-link"></a>
                                </div>
                            </article>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>

 
    </div>
   
   
   
    </>
  )
}

export default Innerpages