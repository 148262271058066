import React from 'react';

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '10px', zIndex: 1 }} // Custom position
      onClick={onClick}
    >
      {/* <i className="fa fa-chevron-left" />  */}
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '10px', zIndex: 1 }} // Custom position
      onClick={onClick}
    >
      {/* <i className="fa fa-chevron-right" />  */}
    </div>
  );
};
export { CustomPrevArrow, CustomNextArrow };