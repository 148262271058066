import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FaPlus, FaMinus, FaBars, FaTimes } from 'react-icons/fa';
import './style.css';


const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <div className={`mil-top-position mil-fixed `}>
      <div className={`mil-top-panel ${  'mil-top-panel-transparent mil-animated'}`}>
        <div className="container">
          <NavLink to="/" className="mil-logo" onClick={() => window.scrollTo(0, 0)}></NavLink>
          <div className="mil-navigation">
            <nav>
           
              <ul className="mil-desktop-menu">
              <li>
                  <NavLink to="/about" onClick={() => window.scrollTo(0, 0)}>Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about" onClick={() => window.scrollTo(0, 0)}>About us</NavLink>
                </li>
                <li>
                  <NavLink to="/about" onClick={() => window.scrollTo(0, 0)}>Service</NavLink>
                </li>
                <li><NavLink to="/blog" onClick={() => window.scrollTo(0, 0)}>Blog</NavLink></li>
                <li><NavLink to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink></li>
              </ul>
            </nav>
          </div>
          {/* mobile menu button */}
          <div className="mil-menu-btn" style={{ fontSize: '24px' }} onClick={toggleMenu}>
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
          {/* mobile menu button end */}
        </div>
      </div>
      {/* mobile menu */}
      {isMenuOpen && (
        <div className="mil-mobile-menu">
          <ul>
          <li>
                  <NavLink to="/about" onClick={() => window.scrollTo(0, 0)}>Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about" onClick={() => window.scrollTo(0, 0)}>About us</NavLink>
                </li>
                <li>
                  <NavLink to="/about" onClick={() => window.scrollTo(0, 0)}>Service</NavLink>
                </li>
                <li><NavLink to="/blog" onClick={() => window.scrollTo(0, 0)}>Blog</NavLink></li>
                <li><NavLink to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact Us</NavLink></li>
          </ul>
        </div>
      )}
      {/* mobile menu end */}
    </div>
  );
};

export default Navbar;
