import './App.css';
import Slider from './Home/Banner';
import Innerpages from './Home/Innerpages';
import Footer from './Home/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/css/pbminfotech-base-icons.css";
import BlogCarousel from './Home/BlogCarousel';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CounterSection from './Home/CounterSection';
import SectionMarquee from './Home/SectionMarquee'
import Navbar from './Home/Navbar';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Define a route for the Slider component */}
        <Route path="/" element={<><Navbar/><Slider/><Innerpages/> <Footer/></>} />
        {/* Define other routes */}
       
        {/* Add any additional routes here */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
